import { Injectable } from "@angular/core";
import type {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from "@angular/router";
import { GeoJSONFeatureCollection } from "ol/format/GeoJSON";
import { Observable } from "rxjs";
import { GeoserverAPIService } from "src/app/services/geoserver/geoserver-api.service";
import { TREIN_LAYER_NAME, TREIN_WORKSPACE } from "src/assets/constants/constants";

@Injectable({providedIn:'root'})
export class TreinResolver implements Resolve<GeoJSONFeatureCollection> {
  constructor(private geoServer: GeoserverAPIService) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<GeoJSONFeatureCollection> | Promise<GeoJSONFeatureCollection> | GeoJSONFeatureCollection {
    return this.geoServer
    .getTreinFeatures(TREIN_WORKSPACE, TREIN_LAYER_NAME)
  }
}
