import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import GeoJSON from "ol/format/GeoJSON";
import { EventsKey } from "ol/events";
import { OLMapService } from "../../services/ol-map/ol-map.service";
import { unByKey } from "ol/Observable";
import { AtlasBaseMapComponent } from "../../components/ol-map/components/atlas-base-map.component";
import { TreinMapService } from "../services/trein-map.service";
import { Feature, MapBrowserEvent } from "ol";
import { Geometry } from "ol/geom";
import { HttpClient } from "@angular/common/http";
import { BootstrapMediaService } from "src/app/services/bootstrap-media.service";
import { TranslationHelperService } from "src/app/services/translations/translation-helper.service";
import { forkJoin } from "rxjs";
import { BBOX } from "src/assets/constants/constants";
import { unsubscribe } from "src/app/utils/helpers";

@Component({
  selector: "app-atlas-trein-map",
  templateUrl: "./atlas-trein-map.component.html",
  styleUrls: ["./atlas-trein-map.component.scss"],
})
export class AtlasTreinMapComponent
  extends AtlasBaseMapComponent
  implements OnInit, OnDestroy
{
  private moveendKey: EventsKey;
  private clickKey: EventsKey;
  options: any;
  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    public olMapService: OLMapService,
    public treinService: TreinMapService,
    protected http: HttpClient,
    protected breakpointObserver: BootstrapMediaService,
    protected translationHelper: TranslationHelperService
  ) {
    super(http, breakpointObserver, translationHelper, olMapService);
  }
  ngOnInit(): void {
    this.fitInitialMapView();
    this.treinService.getFilter("trein-filter");
    this.treinService.getDetails("trein-detail");
    this.treinService.getLegends("trein-legend");
    this.loadLayer();
    this.updateBbox();
    this.mapOperations();
  }

  fitInitialMapView() {
    const bBoxArr = this.olMapService.mapConfig[BBOX]
      ? this.olMapService.mapConfig[BBOX].split(",").map((bbox) => Number(bbox))
      : "";
    if (bBoxArr != "") {
      this.olMapService.map.getView().fit(bBoxArr);
    }
    const queryParams = {
      bbox: bBoxArr.toString(),
    };
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
      replaceUrl: true,
      queryParamsHandling: "merge",
    });
  }

  updateBbox() {
    this.moveendKey = this.olMapService.map.on("moveend", () => {
      const extent = this.olMapService.map
        .getView()
        .calculateExtent(this.olMapService.map.getSize())
        .toString();
      const queryParams = {
        bbox: extent,
      };
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams,
        replaceUrl: true,
        queryParamsHandling: "merge",
      });
    });
  }

  mapOperations(): void {
    this.clickKey = this.olMapService.map.on(
      "singleclick",
      (event: MapBrowserEvent<MouseEvent>) => {
        const joinEndpoints = this.treinService.mapOperations(event);
        this.subscriptions.push(
          forkJoin(joinEndpoints).subscribe((featureCollections: GeoJSON[]) => {
            featureCollections.map((featureCollection: GeoJSON) => {
              const format = new GeoJSON();
              const features: Feature<Geometry>[] =
                format.readFeatures(featureCollection);
              if (features.length > 0) {
                this.updateDetails(features[0]);
                this.addVectorLayer(features[0], format);
              }
            });
          })
        );
      }
    );
  }
  loadLayer(): void {
    this.treinService.loadLayer();
  }
  addVectorLayer(feature: Feature<Geometry>, format: GeoJSON) {
    this.treinService.addVectorLayer(feature, format);
  }
  setFilter(): void {}
  getLayerDetails(feature: Feature<Geometry>): void {}
  getLayerName(): string {
    return "";
  }
  updateDetails(feature: Feature<Geometry>) {
    this.treinService.updateDetails(feature);
  }
  getStyleName(): string {
    return "";
  }
  ngOnDestroy(): void {
    unByKey(this.moveendKey);
    unByKey(this.clickKey);
    unsubscribe(this.subscriptions);
  }
}
