import { Component, Input, OnInit } from "@angular/core";
import { faSpinnerThird as falSpinnerThird } from "@fortawesome/pro-light-svg-icons/faSpinnerThird";
import { faTimes as falTimes } from "@fortawesome/pro-light-svg-icons/faTimes";
import { faFilter as falFilter } from "@fortawesome/pro-light-svg-icons/faFilter";
import { TreinFormService } from "../services/trein-form.service";
import { Router } from "@angular/router";
import { OLMapService } from "src/app/services/ol-map/ol-map.service";
import TileLayer from "ol/layer/Tile";
import TileWMS from "ol/source/TileWMS";
import { TreinMapService } from "../services/trein-map.service";
import { AtlasFilterBaseComponent } from "src/app/components/ol-map/ol-map-filters/atlas-filter-base.component";
import { DatasetEntry } from "src/app/models/dataset-entry";
import { TREIN_FILTER_LABELS } from "src/assets/constants/constants";

@Component({
  selector: "app-atlas-trein-filter",
  templateUrl: "./atlas-trein-filter.component.html",
  styleUrls: ["./atlas-trein-filter.component.scss"],
})
export class AtlasTreinFilterComponent
  extends AtlasFilterBaseComponent
  implements OnInit
{
  @Input() options: any;
  get form() {
    return this.formService.form;
  }
  public formLabels = TREIN_FILTER_LABELS
  public parameters: any;
  readonly icons = {
    falTimes,
    falSpinnerThird,
    falFilter,
  };

  constructor(
    private formService: TreinFormService,
    private readonly router: Router,
    public readonly olMapService: OLMapService,
    private readonly treinMapService: TreinMapService
  ) {
    super();
  }
  ngOnInit(): void {
    this.updateRoute(this.form.value);
    this.form.valueChanges.subscribe((value) => {
      this.updateRoute(value);
    });
  }
  updateRoute(value: any) {
    this.router.navigate([], {
      queryParamsHandling: "merge",
      queryParams: value,
    });
    const tileLayers = this.olMapService.map
      .getLayers()
      .getArray()
      .filter(
        (l) =>
          l instanceof TileLayer &&
          l.getSource() instanceof TileWMS &&
          l.getVisible()
      ) as TileLayer<TileWMS>[];
    this.olMapService.map.removeLayer(tileLayers[0]);
    this.treinMapService.loadLayer();
    this.olMapService.map.render();
  }
  setFilter(filter: string, value: any) {}
  changeDataset(entry: DatasetEntry) {}
}
