import { NgModule } from "@angular/core";
import { ExtraOptions, RouterModule, Routes } from "@angular/router";
import { HomePageComponent } from "./pages/home-page/home-page.component";
import { CrowdsourcingPageComponent } from "./pages/crowdsourcing-page/crowdsourcing-page.component";
import { AtlasMobilePageComponent } from "./pages/atlas-mobile-page/atlas-mobile-page.component";
import { AtlasFixPageComponent } from "./pages/atlas-fix-page/atlas-fix-page.component";
import { LoginPageComponent } from "./pages/login-page/login-page.component";
import { ProjectLayoutComponent } from "./pages/project-layout/project-layout.component";
import { ProjectResolver } from "./services/project-resolver.service";
import { LanguageResolver } from "./services/language-resolver";
import { DriveTestPageComponent } from "./pages/drive-test-page/drive-test-page.component";
import { OpendataPageComponent } from "./pages/opendata-page/opendata-page.component";
import { RedirectToLanguageGuard } from "./guards/RedirectToLanguageGuard";
import { TermsPageComponent } from "./pages/terms-page/terms-page.component";
import { IndicatorsPageComponent } from "./pages/indicators/indicators-page.component";
import { AtlasFtthPageComponent } from "./pages/atlas-ftth-page/atlas-ftth-page.component";
import { OlMapComponent } from "./components/ol-map/ol-map.component";
import { AtlasPageComponent } from "./pages/atlas-page/atlas-page.component";
import { DataPageComponent } from "./pages/data-page/data-page.component";
import { AtlasTreinMapComponent } from "./treinmetingen/map/atlas-trein-map.component";
import { TreinResolver } from "./treinmetingen/services/trein.resolver";
import { AtlasTreinPageComponent } from "./treinmetingen/pages/atlas-trein-page/atlas-trein-page.component";

export const routes: Routes = [
  {
    path: ":language",
    resolve: {
      language: LanguageResolver,
    },
    children: [
      // { path: '', pathMatch: 'full', redirectTo: 'projects/atlas/landline' },
      {
        path: "",
        component: HomePageComponent,
        pathMatch: "full",
      },
      {
        path: "atlas",
        component: AtlasPageComponent,
      },
      {
        path: "data",
        component: DataPageComponent,
      },
      { path: "login", component: LoginPageComponent },
      {
        path: "projects",
        component: ProjectLayoutComponent,
        runGuardsAndResolvers: "always",
        // canActivate: [AuthenticatedGuard],
        resolve: {
          datasource: ProjectResolver,
        },
        children: [
          // { path: 'crowdsourcing', component: CrowdsourcingPageComponent },
          {
            path: "atlas",
            component: OlMapComponent,
            children: [
              { path: "ftth", component: AtlasFtthPageComponent },
              { path: "mobile", component: AtlasMobilePageComponent },
              { path: "landline", component: AtlasFixPageComponent },
              {
                path: "trein",
                component: AtlasTreinPageComponent,
                resolve: {
                  treinFeatures: TreinResolver,
                },
              },
            ],
          },
          {
            path: "data",
            children: [
              { path: "opendata", component: OpendataPageComponent },
              { path: "indicators", component: IndicatorsPageComponent },
              { path: "userexperience", component: DriveTestPageComponent },
            ],
          },
          // {path: 'barometer', component: BarometerPageComponent},
        ],
      },
      { path: "terms", component: TermsPageComponent },
      // { path: 'applications', component: ApplicationsPageComponent },
    ],
  },
  { path: "**", canActivate: [RedirectToLanguageGuard], children: [] },
];

const config: ExtraOptions = {
  useHash: false,
  enableTracing: false,
  scrollPositionRestoration: "top",
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
